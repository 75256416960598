import React from 'react';
import './App.css';
import Main from './components/Main.js';

function App() {
  return (
    <div>
      <div className="flex w-full">
<Main />
      </div>
    </div>
  );
}

export default App;